import React from "react";
// import { Link } from "gatsby";
import Layout from "../../components/layout";
// import { RecrutationForm } from "../../components";

const StrategyPlanner = ({ location: { pathname }, pageContext }) => {
  const title = "Strategy Planner";

  return (
    <Layout
      seo={{
        title: title,
        href: pathname,
        lang: "pl",
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/career/strategy-planner/",
      }}
    >
      {/* <section className="page_recrutation">
        <h1>Kariera</h1>
        <div className="row">
          <div className="col-md-3">
            <ul className="categories">
              <li className="cat-item">
                <Link to="/kariera/account-specialist">Account Specialist</Link>
              </li>
              <li className="cat-item active">
                <Link to="/kariera/strategy-planner">Strategy Planner</Link>
              </li>
              <li className="cat-item">
                <Link to="/kariera/web-designer">Web Designer</Link>
              </li>
              <li className="cat-item">
                <Link to="/kariera/react-developer">React.js Developer</Link>
              </li>
              <li className="cat-item">
                <Link to="/kariera/php-developer">PHP Developer</Link>
              </li>
              <li className="cat-item">
                <Link to="/kariera/python-developer">
                  Python & Django developer
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-8 offset-md-1">
            <div className="panel-description">
              <p>
                Miejsce pracy: <strong>Kraków</strong>
              </p>
              <p>
                ADream Sp.z o.o poszukuje doświadczonych strategów, którzy będą
                odpowiedzialni za tworzenie i wdrażanie długoterminowych
                strategii marketingowych dla naszych Klientów. Jesteśmy grupą
                spółek z branży reklamowo-marketingowej – każda z nich ma swoją
                wąską specjalizację, ale dzięki wspólnemu działaniu oferujemy
                Klientom szeroki wachlarz usług. Szukamy osób, z którymi
                będziemy mogli działać jeszcze szerzej, skonfrontować różne
                kierunki myślenia o marketingu oraz czerpać nowe know how
                branżowe.
              </p>
              <h2>Co będzie należało do Twoich obowiązków?</h2>
              <p>
                Twoim zadaniem będzie wprowadzenie do naszych strategii nowych
                inspiracji, wiedzy i unikalnego know how. Nie chcemy ograniczać
                Cię do konkretnych obszarów strategicznych – powiedz nam w czym
                jesteś dobry: w badaniach konsumenckich, strategiach
                komunikacji, brandingu, kampaniach 360, strategiach digital
                marketingu. Będziemy wymagać biegłości w monitorowaniu rynku,
                trendów, narzędzi, konkurencji i badań oraz analizie
                efektywności realizowanych przez nas działań. Potrzebujemy osób,
                które zarekomendują jeszcze lepsze ścieżki rozwoju dla naszych
                Klientów.
              </p>
              <h2>Wymagania:</h2>
              <ul>
                <li>
                  kilkuletnie doświadczenie w tworzeniu strategii
                  marketingowych;
                </li>
                <li>doskonała znajomość mediów tradycyjnych jak i digital;</li>
                <li>sprawne łączenie kreatywności i analitycznego myślenia;</li>
                <li>wysoka kultura osobista;</li>
                <li>doskonałe zdolności komunikacyjne;</li>
                <li>biegła znajomość języka angielskiego;</li>
                <li>
                  znajomość zagadnień związanych z badaniami rynku, analizą
                  marketingową i biznesową;
                </li>
                <li>
                  podstawowa znajomość zagadnień z zakresu psychologii,
                  socjologii, ekonomii.
                </li>
              </ul>
              <h2>Co możemy Ci zaoferować?</h2>
              <ul>
                <li>
                  Pracę w systemie projektowym (może także na etat –
                  porozmawiamy o tym);
                </li>
                <li>możliwość tworzenia strategii dla dużych Klientów;</li>
                <li>
                  współpracę ze specjalistami z naszych firm m.in. tworzenie
                  stron www, marketing internetowy, identyfikacje wizualne i
                  branding;
                </li>
                <li>
                  partnerów, dla których będziesz mógł realizować projekty B2B;
                </li>
                <li>
                  wynagrodzenie będzie określone indywidualnie na podstawie
                  posiadanego doświadczenia oraz ustalonego zakresu współpracy.
                </li>
              </ul>
              <h2>
                Spotkajmy się My pokażemy Ci jak do tej pory pracowaliśmy, a Ty
                pokaż nam perełki wśród swoich strategii. Chcielibyśmy wiedzieć:
              </h2>
              <ul>
                <li>Ile masz lat doświadczenia w branży?</li>
                <li>Ile strategii opracowałeś?</li>
                <li>
                  Jaki zakres miały strategie? (Jak bardzo były obszerne?)
                </li>
                <li>
                  Dla jakich marek przygotowywałeś strategie i jakie cele udało
                  Ci się osiągnąć? (Opowiedz nam o 3 najbardziej rozpoznawalnych
                  firmach.
                </li>
              </ul>
              <RecrutationForm position={title || ""} />
            </div>
          </div>
        </div>
      </section> */}
    </Layout>
  );
};
export default StrategyPlanner;
